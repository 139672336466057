import { Link, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Row, Col, Image, ListGroup, Card, Button, FormControl } from "react-bootstrap";
import Rating from "../components/Rating";
import { useGetProductDetailsQuery, useCreateReviewMutation } from "../slices/productsApiSlice";
import Loader from "../components/Loader";
import Message from "../components/Message";
import { useState } from "react";
import { addToCart } from "../slices/cartSlice";
import { toast } from 'react-toastify';
import { Form } from 'react-bootstrap';
import Meta from '../components/Meta';

const ProductScreen = () => {
   const { id: productId } = useParams();

   const dispatch = useDispatch();

   const [qty, setQty] = useState(1);
   const [rating, setRating] = useState(0);
   const [comment, setComment] = useState('');

   const {
      data: product,
      isLoading,
      refetch,
      error,
   } = useGetProductDetailsQuery(productId);

   const [createReview, { isLoading: loadingProductReview }] = useCreateReviewMutation();

   const { userInfo } = useSelector((state) => state.auth);

   const addToCartHandler = () => {
      dispatch(addToCart({ ...product, qty }));
   }

   const submitHandler = async (e) => {
      e.preventDefault();

      try {
         const response = await createReview({
            productId,
            rating,
            comment,
         }).unwrap();
         refetch();
         if (response.message === 'Review added')
            toast.success('Review created successfully');
         else if (response.message === 'Review updated')
            toast.success('Review updated successfully');
      } catch (err) {
         toast.error(err?.data?.message || err.error);
      }
   };

   return (
      <>
         <Link to="/" className="btn btn-light my-3">
            Go Back
         </Link>

         {isLoading ? (
            <h2><Loader /></h2>
         ) : error ? (
            <Message variant='danger'>{error?.data?.message || error.error}</Message>
         ) : (
            <>
               <Meta title={product.name} description={product.description} />
               <Row>
                  <Col md={5}>
                     <Image src={product.image} alt={product.name} fluid />
                  </Col>
                  <Col md={4}>
                     <ListGroup variant="flush">
                        <ListGroup.Item>
                           <h3>{product.name}</h3>
                        </ListGroup.Item>

                        <ListGroup.Item>
                           <Rating
                              value={product.rating}
                              text={`${product.numReviews} reviews`}
                           />
                        </ListGroup.Item>

                        <ListGroup.Item>Price: Rs.{product.price}</ListGroup.Item>
                        <ListGroup.Item>
                           Description: {product.description}
                        </ListGroup.Item>
                     </ListGroup>
                  </Col>
                  <Col md={3}>
                     <Card>
                        <ListGroup variant="flush">
                           <ListGroup.Item>
                              <Row>
                                 <Col>Price:</Col>
                                 <Col>
                                    <strong>Rs.{product.price}</strong>
                                 </Col>
                              </Row>
                           </ListGroup.Item>

                           <ListGroup.Item>
                              <Row>
                                 <Col>Status:</Col>
                                 <Col>
                                    {product.countInStock > 0 ? "In Stock" : "Out Of Stock"}
                                 </Col>
                              </Row>
                           </ListGroup.Item>

                           {product.countInStock > 0 && (
                              <ListGroup.Item>
                                 <Row>
                                    <Col>Qty</Col>
                                    <Col>
                                       <FormControl
                                          as='select'
                                          value={qty}
                                          onChange={(e) => setQty(Number(e.target.value))}>
                                          {[...Array(product.countInStock).keys()].map((x) => (
                                             x < 10 && <option key={x + 1} value={x + 1}>
                                                {x + 1}
                                             </option>
                                          ))}

                                          {/* [...Array(product.countInStock).keys()]
                                  if a item's countIn stock is 10 then the above expression will
                                  give an array of numbers from 0 to 9
                              */}

                                       </FormControl>
                                    </Col>
                                 </Row>
                              </ListGroup.Item>
                           )}

                           <ListGroup.Item>
                              <Button
                                 className="btn-block"
                                 type="button"
                                 disabled={product.countInStock === 0}
                                 onClick={addToCartHandler}
                              >
                                 Add To Cart
                              </Button>
                           </ListGroup.Item>
                        </ListGroup>
                     </Card>
                  </Col>
               </Row>
               <Row className='review'>
                  <Col md={6}>
                     <h2>Reviews</h2>
                     {product.reviews.length === 0 && <Message>No Reviews</Message>}
                     <ListGroup variant='flush'>
                        {product.reviews.map((review) => (
                           <ListGroup.Item key={review._id}>
                              <strong>{review.name}</strong>
                              <Rating value={review.rating} />
                              <p>{review.createdAt.substring(0, 10)}</p>
                              <p>{review.comment}</p>
                           </ListGroup.Item>
                        ))}
                        <ListGroup.Item>
                           <h2>Write a Customer Review</h2>

                           {loadingProductReview && <Loader />}

                           {userInfo ? (
                              <Form onSubmit={submitHandler}>
                                 <Form.Group className='my-2' controlId='rating'>
                                    <Form.Label>Rating</Form.Label>
                                    <Form.Control
                                       as='select'
                                       required
                                       value={rating}
                                       onChange={(e) => setRating(e.target.value)}
                                    >
                                       <option value=''>Select...</option>
                                       <option value='1'>1 - Poor</option>
                                       <option value='2'>2 - Fair</option>
                                       <option value='3'>3 - Good</option>
                                       <option value='4'>4 - Very Good</option>
                                       <option value='5'>5 - Excellent</option>
                                    </Form.Control>
                                 </Form.Group>
                                 <Form.Group className='my-2' controlId='comment'>
                                    <Form.Label>Comment</Form.Label>
                                    <Form.Control
                                       as='textarea'
                                       row='3'
                                       required
                                       value={comment}
                                       onChange={(e) => setComment(e.target.value)}
                                    ></Form.Control>
                                 </Form.Group>
                                 <Button
                                    disabled={loadingProductReview}
                                    type='submit'
                                    variant='primary'
                                 >
                                    Submit
                                 </Button>
                              </Form>
                           ) : (
                              <Message>
                                 Please <Link to='/login'>Sign in</Link> to write a review
                              </Message>
                           )}
                        </ListGroup.Item>
                     </ListGroup>
                  </Col>
               </Row>
            </>
         )}
      </>
   );
};

export default ProductScreen;
